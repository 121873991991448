import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';
interface Props {
  isActive?: boolean;
  isRead?: boolean;
  isScrollPage?: boolean;
  isImage?: boolean;
  buttonHeight?: number;
  direction?: 'top' | 'bottom';
  alignment?: 'left' | 'right' | 'center';
  dropdownTopOffset?: number | null; // Use the calculated top offset
  isIconActive?: boolean;
  isTaskDetailPreview?: boolean;
  isDisabled?: boolean;
}

const Buttons = styled.div<Props>`
  ${Typography.body_md_medium};
  color: var(--text-secondary);
  padding: 6px 15px;
  border: 0.5px solid transparent;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    border: 0.5px solid var(--border-primary);
    background-color: var(--neutral-secondary);
    border-radius: 999px;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      border-radius: 999px;
      color: var(--text-primary);
    `}
  @media (max-width: 449px) {
    padding: 6px 15px;
  }
`;

const HeaderInbox = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 662px;
  padding: 14px 0 0;
  margin: auto;
  position: relative;
  align-items: center;
  @media (max-width: 449px) {
    max-width: 100%;
    gap: 0px;
    margin: 0;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
  }
  .ant-dropdown {
    z-index: 9999;
  }
`;
const Mainheader = styled.div<Props>`
  position: sticky;
  top: 0;
  z-index: 999;
  margin: 0 -24px;
  ::after {
    content: '';
    width: 100%;
    height: 92px;
    display: block;
    position: absolute;
    top: 0px;
    z-index: -1;
    background-image: var(--background-linear-gradient);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    pointer-events: none;
  }
  @media (max-width: 449px) {
    margin: 0;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: var(--background-secondary);
    ::after {
      background-image: none;
    }
    ${(props: Props) =>
      props.isScrollPage &&
      css`
        background-image: var(--background-linear-gradient);
        ::after {
          top: 56px;
        }
      `};
  }
`;

const InboxMSG = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 662px;
  margin: 26px auto 24px;
  position: relative;
  @media (max-width: 449px) {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 80px;
  }
`;

const MSG = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 12px 15px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  align-items: baseline;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
  -webkit-tap-highlight-color: transparent; /* Optional: remove tap highlight on touch */
  .inbox-icons {
    display: none;
    @media (max-width: 449px) {
      display: flex;
    }
  }

  :hover {
    box-shadow: var(--shadow-Card);
    border-radius: 12px;
    .inbox-icons {
      display: flex;
    }
  }

  .pointer {
    visibility: visible;
    position: relative;
    margin-left: -5px;
    @media (max-width: 449px) {
      margin-left: 0;
    }
  }
  ${(props: Props) =>
    props.isIconActive &&
    css`
      .inbox-icons {
        display: flex;
      }
    `}
  ${(props: Props) =>
    props.isRead &&
    css`
      background-color: var(--background-primary);
      .pointer {
        visibility: hidden;
      }
    `}
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    background-color: transparent;
    border: none;
    margin-left: -8px;
  }
`;

const Avtardot = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  @media (max-width: 449px) {
    gap: 6px;
    .avatar {
      width: 42px;
      height: 42px;
      border: none;
    }
    .svg-icon {
      width: 42px;
      height: 42px;
    }
    .github-icon {
      width: 42px;
      height: 42px;
    }
  }
`;

const MSGdetail = styled.div`
  display: flex;
  gap: 13px;
  width: 100%;
  @media (max-width: 449px) {
    gap: 12px;
    &.overdue-card {
      gap: 18px;
    }
    &.desktopnotification-card {
      gap: 18px;
    }
    &.notification-card {
      gap: 18px;
    }
  }
`;

const IconView = styled.div`
  .taskicon {
    stroke: var(--text-secondary);
    fill: var(--neutral-secondary);

    rect {
      fill: var(--neutral-secondary);
      stroke: var(--background-primary);
    }
    position: absolute;
    left: 34px;
    margin-top: 17px;
  }
  .project-invited-icon {
    position: absolute;
    left: 34px;
    margin-top: 17px;
  }
  @media (max-width: 449px) {
    .taskicon {
      left: 40px;
      margin-top: 24px;
      stroke: var(--text-primary);
      fill: var(--neutral-secondary);
      width: 18px;
      height: 18px;
      rect {
        fill: var(--background-muted);
        stroke: var(--background-secondary);
      }
    }
    .dot-icon {
      width: 6px;
      height: 6px;
      margin-top: 18px;
    }
    .project-invited-icon {
      left: 40px;
      margin-top: 24px;
    }
  }
`;
const BlankDiv = styled.div`
  width: 5px;
  height: 6px;
`;

const MSGtime = styled.div`
  position: relative;
  @media (max-width: 449px) {
    display: none;
  }
`;

const Desc = styled.div<Props>`
  padding: 5px 11px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  word-break: break-word;
  margin: 0;
  ${Typography.body_md_medium};
  color: var(--text-primary);
  ${(props: Props) =>
    props.isImage &&
    css`
      padding: 12px;
    `}
  .prosemirror-mention-node {
    color: var(--brand-primary) !important;
  }
  img {
    display: block;
    width: 88px;
    height: 74px;
    border-radius: 4px;
    padding: 0;
  }
  .figma-main-div {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    justify-content: flex-end;
    padding-top: 6px;
    @media (max-width: 320px) {
      flex-direction: column-reverse;
      padding-bottom: 0px;
    }
  }
  .figma-icon {
    display: none;
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_md_medium};
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .figma-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media (max-width: 449px) {
      gap: 2px;
    }
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm};
  }
  .figma-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .figma-animation {
    display: none;
  }
  .figma-link-div {
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none;
    border-radius: 36px;
    ${Typography.body_sm_medium};
    &:hover {
      background-color: var(--background-secondary);
      border-radius: 36px;
    }
  }
  .screen-recording-custom-main-div {
    display: none;
  }
  p {
    margin: 0;
    ${Typography.body_md_medium};
    color: var(--text-primary);
  }
  span {
    margin: 0;
    ${Typography.body_md_medium};
    color: var(--text-primary);
  }
  .ant-image {
    max-width: 88px;
    max-height: 74px;
    margin: 0;
  }
  .inbox-image {
    width: 88px;
    height: 74px;
    border-radius: 4px;
    color: var(--text-primary);
    background: var(--border-primary);
    img {
      height: 100%;
      margin: 0 auto;
      object-fit: contain;
    }
    .ant-image .ant-image-img {
      width: 88px !important;
      height: 74px !important;
      border-radius: 4px !important;
    }
  }
  .ant-image .ant-image-mask {
    border-radius: 4px !important;
  }
  .ant-image .ant-image-img {
    max-width: 88px !important;
    max-height: 74px !important;
    border-radius: 4px !important;
  }
  a {
    color: var(--text-link);
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
  ul,
  ol {
    color: var(--text-primary) !important;
    background-color: transparent !important;
    li {
      ::marker {
        color: var(--text-primary) !important;
        background-color: transparent !important;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    ${Typography.body_md_medium};
    color: var(--text-primary);
  }
  pre {
    white-space: normal;
    margin: 0;
  }
  .e-rte-table td,
  .e-rte-table th {
    border: 0.5px solid var(--border-primary);
  }
  @media (max-width: 449px) {
    .figma-filename {
      ${Typography.body_sm};
    }
    .figma-header {
      gap: 6px;
    }
    .ant-image .ant-image-img {
      border: 0.8px solid var(--border-primary);
    }
  }
`;

const Read = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  @media (max-width: 449px) {
    gap: 4px;
  }
`;

const MSGtitle = styled.div`
  ${Typography.body_md_medium};
  color: var(--text-primary);
  margin: 0;
  p {
    margin: 0;
    span {
      background-color: unset !important;
    }
  }
  a {
    color: var(--text-link) !important;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
  .inbox-image {
    width: 88px;
    height: 74px;
    border-radius: 4px;
    background-color: var(--border-primary);
    img {
      height: 100%;
      margin: 0 auto;
      object-fit: contain;
    }
  }
  .dot-icon {
    display: none;
  }
  @media (max-width: 449px) {
    ${Typography.body_lg};
    color: var(--text-secondary);
    .dot-icon {
      display: inline;
      ${Typography.body_lg_medium};
    }
    &.overdue-card {
      ${Typography.body_lg_medium};
      color: var(--text-primary);
    }
    &.notification-card {
      ${Typography.body_lg_medium};
      color: var(--text-primary);
    }
    &.desktopnotificarion-card {
      ${Typography.body_lg_medium};
      color: var(--text-primary);
    }
  }
`;

const MSGtext = styled.p`
  ${Typography.body_md};
  color: var(--text-secondary);
  margin: 0;
  a {
    color: var(--text-link);
  }
  @media (max-width: 449px) {
    ${Typography.body_lg_medium};
    color: var(--text-primary);
    &.overdue-card {
      ${Typography.body_lg};
      color: var(--text-secondary);
    }
    &.notification-card {
      ${Typography.body_lg};
      color: var(--text-secondary);
    }
    &.desktopnotificarion-card {
      ${Typography.body_lg};
      color: var(--text-secondary);
    }
  }
`;

const MSGsubtext = styled.p`
  ${Typography.body_md};
  color: var(--text-secondary);
  margin: 0;
  white-space: nowrap;
  @media (max-width: 449px) {
    display: none;
  }
`;

const MSGblock = styled.div`
  width: 100%;
  max-width: 514px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  @media (max-width: 449px) {
    max-width: 100%;
    gap: 6px;
  }
`;
const Icons = styled.div<Props>`
  display: flex;
  gap: 6px;
  width: 100%;
  padding: 2px 3px;
  border-radius: 13px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  box-shadow: var(--shadow-card);
  .icons {
    cursor: pointer;
    &:hover {
      svg {
        path {
          stroke: var(--brand-primary);
        }
      }
    }
  }
  .icon {
    cursor: pointer;
    path {
      fill: var(--text-secondary);
    }
    &:hover {
      path {
        fill: var(--brand-primary);
      }
    }
  }
  ${(props: Props) =>
    props.isTaskDetailPreview &&
    css`
      padding: 6px;
      flex-direction: column;
      width: max-content;
      border-radius: 42px;
      border: 0.5px solid var(--border-primary);
    `}
  @media (max-width: 449px) {
    padding: 3px;
    gap: 8px;
    ${(props: Props) =>
      props.isTaskDetailPreview &&
      css`
        flex-direction: row;
        padding: 8px;
        gap: 12px;
        box-shadow: var(--shadow-card-hover);
      `}
  }
`;

const SvgDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .icons {
    svg {
      path {
        stroke: var(--text-secondary);
      }
    }
  }
  .icon {
    path {
      fill: var(--text-secondary);
    }
  }
  ${(props: Props) =>
    props.isTaskDetailPreview &&
    css`
      width: 24px;
      height: 24px;
      :hover {
        background: var(--neutral-secondary);
        border-radius: 50%;
        .icons {
          svg {
            path {
              stroke: var(--brand-primary);
            }
          }
        }
        .icon {
          path {
            fill: var(--brand-primary);
          }
        }
      }
      @media (max-width: 449px) {
        padding: 2px;
        :hover {
          background: transparent;
        }
      }
    `}
`;

const Bottom = styled.div`
  position: absolute;
  top: 22px;
  right: 0;
  display: flex;
  justify-content: end;
  @media (max-width: 449px) {
    /* position: relative;
    top: 0; */
    display: none;
  }
`;
const Bottombutton = styled.div`
  display: flex;
  padding-top: 8px;
  align-items: center;
  gap: 20px;
  p {
    color: var(--text-primary);
    ${Typography.body_sm_medium};
    margin: 0;
    white-space: nowrap;
  }
  @media (max-width: 449px) {
    padding-top: 4px;
    button {
      padding: 4px 15px;
    }
  }
  @media (max-width: 320px) {
    gap: 12px;
    flex-wrap: wrap;
    &.overdue {
      align-items: center;
      gap: 20px;
    }
  }
`;
const Leftside = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;
  @media (max-width: 449px) {
    gap: 24px;
    width: 100%;
    background-color: var(--background-secondary);
    padding: 9px 12px 9px 20px;
    margin: 0 -12px 0 -20px;
  }
`;

const Rightside = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  .large-more-icon {
    fill: var(--text-secondary);
  }
  svg {
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  @media (max-width: 449px) {
    display: none;
  }
`;
const ResponsiveArchiveIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
    }
    .task-detail-dot-icon {
      fill: var(--text-secondary);
    }
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  max-width: 602px;
  width: 100%;
  @media (max-width: 449px) {
    display: none;
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 449px) {
    justify-content: space-between;
    width: 100%;
    .inbox-header-icon {
      display: none;
    }
  }
`;
const Title = styled.h1`
  ${Typography.heading_lg};
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  @media (max-width: 449px) {
    gap: 5px;
  }
`;
const Empty = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: calc(50% + 110px);
  @media (max-width: 449px) {
    left: 50%;
  }
`;

const Arrowicon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  cursor: pointer;
  .svgicon {
    stroke: var(--text-primary);
    width: 18px;
    height: 18px;
  }
  .arrow-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 54px;
  padding: 11px 16px;
  border-bottom: 0.5px solid var(--border-primary);
  p {
    color: var(--text-primary);
    ${Typography.body_md_medium};
    margin: 0;
  }
`;
const Calendardropdown = styled.div<Props>`
  display: flex;
  flex-direction: column;
`;

const Bottomdiv = styled.div`
  padding: 12px;
  border-top: 0.5px solid var(--border-primary);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Buttonsdiv = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  .button {
    width: 100%;
  }
`;
const Inputdiv = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
const Inputs = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
const Inputlabel = styled.div`
  color: var(--text-secondary);
  ${Typography.body_md_medium};
`;
const Inputvalue = styled.input`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 8px 12px;
  ${Typography.body_lg};
  color: var(--text-primary);
  outline: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    ${Typography.body_lg};
    color: var(--text-secondary);
  }
`;
const Input = styled.div`
  background: var(--background-primary);
  &:focus-visible {
    outline: none;
  }
  .ant-picker {
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: none;
  }
  .ant-picker .ant-picker-input > input {
    color: var(--text-primary);
  }
  .ant-btn-primary {
    background: var(--brand-primary);
    border: none;
  }
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: var(--brand-primary-hover);
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
    border: none !important;
  }
  .ant-picker-dropdown {
    min-width: 122px !important;
  }
  .ant-picker-dropdown .ant-picker-ranges > li {
    line-height: normal;
  }
  @media (max-width: 449px) {
    .ant-picker-dropdown .ant-picker-time-panel-column {
      overflow-y: auto;
    }
  }
`;

const Content = styled.div`
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  max-width: 1122px;
  width: 100%;
  height: calc(100dvh - 72px);
  @media (max-width: 449px) {
    height: 100dvh;
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(249, 249, 250, 0.6);
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const InboxPreviewAction = styled.div`
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 42px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  @media (max-width: 449px) {
    flex-direction: row;
    padding: 8px;
    gap: 12px;
    box-shadow: var(--shadow-card-hover);
  }
`;
const ActionIcon = styled.div<Props>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  svg {
    path {
      stroke: var(--text-secondary);
    }
  }
  .upper-icon {
    transform: rotate(90deg);
  }
  .down-icon {
    transform: rotate(-90deg);
  }
  @media (max-width: 449px) {
    .upper-icon {
      transform: rotate(0deg);
    }
    .down-icon {
      transform: rotate(180deg);
    }
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    svg {
      path {
        stroke: var(--brand-primary);
      }
    }
  }
  ${(props: Props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      svg {
        path {
          stroke: var(--border-primary);
        }
      }
      :hover {
        background: transparent;
        svg {
          path {
            stroke: var(--border-primary);
          }
        }
      }
    `}
  @media(max-width: 449px) {
    padding: 2px;
    :hover {
      background: transparent;
    }
  }
`;
const ActionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 449px) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    z-index: 99;
  }
`;
const DetailPreview = styled.div`
  background-color: var(--background-primary);
  box-shadow: var(--shadow-card);
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
`;
const PreviewSection = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
`;
const NotificationCard = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 12px 12px 15px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  align-items: baseline;
  cursor: pointer;
  box-shadow: var(--shadow-Card);
  .pointer {
    visibility: visible;
    position: relative;
    margin-left: -5px;
    @media (max-width: 449px) {
      margin-left: 0;
    }
  }
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 4px;
    padding: 8px 8px 8px 14px;
    border: none;
  }
`;
const DesktopIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--accent-caution);
  margin-right: 4px;
  .desktop-icon {
    path {
      fill: var(--text-white);
    }
  }
  @media (max-width: 449px) {
    width: 42px;
    height: 42px;
    .desktop-icon {
      width: 26px;
      height: 26px;
    }
  }
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 426px;
  width: 100%;
`;
const DesktopImage = styled.div`
  max-width: 156px;
  width: 100%;
  border: 0.5px solid var(--background-muted);
  border-radius: 6px;
  display: flex;
  img {
    border-radius: 6px;
  }
  .image {
    max-width: 158px;
    width: 100%;
    height: 98px;
  }
  @media (max-width: 449px) {
    max-width: 100%;
    .image {
      max-width: 100%;
      height: 100%;
    }
  }
`;
const RightContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const FilterIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const DateSection = styled.p`
  display: none;
  @media (max-width: 449px) {
    display: inline;
    ${Typography.body_lg};
    color: var(--text-secondary);
    margin: 0;
  }
`;
const MainItemDiv = styled.div`
  max-width: 218px;
  width: 100%;
`;

const ResponsiveDropdown = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;
export {
  Buttons,
  HeaderInbox,
  InboxMSG,
  MSG,
  MSGdetail,
  MSGtime,
  Desc,
  Read,
  MSGtitle,
  MSGtext,
  MSGsubtext,
  MSGblock,
  Mainheader,
  IconView,
  Avtardot,
  Icons,
  Bottom,
  Bottombutton,
  BlankDiv,
  Leftside,
  Rightside,
  Div,
  Heading,
  Title,
  Menu,
  SvgDiv,
  Empty,
  Arrowicon,
  Header,
  Calendardropdown,
  Bottomdiv,
  Buttonsdiv,
  Inputdiv,
  Inputs,
  Inputlabel,
  Inputvalue,
  Input,
  Content,
  Overlay,
  InboxPreviewAction,
  ActionIcon,
  ActionSection,
  DetailPreview,
  PreviewSection,
  NotificationCard,
  DesktopIcon,
  Description,
  DesktopImage,
  RightContent,
  FilterIcon,
  DateSection,
  MainItemDiv,
  ResponsiveArchiveIcon,
  ResponsiveDropdown
};
