import React, { useRef, useState, useCallback, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button,
  ButtonRow,
  Column,
  DropdownWrapper,
  MainDiv,
  TimeColumns,
  TimeInput,
  TimeOption,
  TimePickerWrapper
} from './style';

interface TimePickerProps {
  value: Dayjs;
  onSelect: (value: Dayjs) => void;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  style?: React.CSSProperties;
  disabled?: boolean;
  format?: string;
  timezone?: string;
  ariaLabel?: string;
}

// Add keyboard navigation constants
const KEYS = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  ENTER: 'Enter',
  ESC: 'Escape',
  TAB: 'Tab'
};

const CustomTimePicker: React.FC<TimePickerProps> = ({
  value,
  onSelect,
  placement = 'topLeft',
  style,
  disabled = false,
  format = 'HH:mm',
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ariaLabel = 'Time picker'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropUp, setDropUp] = useState(false);

  const [selectedHour, setSelectedHour] = useState(value.hour());
  const [selectedMinute, setSelectedMinute] = useState(value.minute());
  const [focusedOption, setFocusedOption] = useState<'hour' | 'minute'>('hour');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const hourColumnRef = useRef<HTMLDivElement>(null);
  const minuteColumnRef = useRef<HTMLDivElement>(null);

  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  // Add validation function
  const validateTime = useCallback((hour: number, minute: number): boolean => {
    return hour >= 0 && hour < 24 && minute >= 0 && minute < 60;
  }, []);

  // Update handlers without timezone
  const handleOk = useCallback(() => {
    if (validateTime(selectedHour, selectedMinute)) {
      const newTime = dayjs().hour(selectedHour).minute(selectedMinute);
      onSelect(newTime);
      setIsOpen(false);
    }
  }, [selectedHour, selectedMinute, onSelect, validateTime]);

  // Handle keyboard navigation
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isOpen) return;

      const currentRef = focusedOption === 'hour' ? hourColumnRef : minuteColumnRef;
      const currentValue = focusedOption === 'hour' ? selectedHour : selectedMinute;
      const setCurrentValue = focusedOption === 'hour' ? setSelectedHour : setSelectedMinute;
      const maxValue = focusedOption === 'hour' ? 23 : 59;

      switch (e.key) {
        case KEYS.UP:
          e.preventDefault();
          const prevValue = currentValue - 1 < 0 ? maxValue : currentValue - 1;
          if (
            validateTime(
              focusedOption === 'hour' ? prevValue : selectedHour,
              focusedOption === 'minute' ? prevValue : selectedMinute
            )
          ) {
            setCurrentValue(prevValue);
            scrollToOption(currentRef.current, prevValue);
          }
          break;
        case KEYS.DOWN:
          e.preventDefault();
          const nextValue = currentValue + 1 > maxValue ? 0 : currentValue + 1;
          if (
            validateTime(
              focusedOption === 'hour' ? nextValue : selectedHour,
              focusedOption === 'minute' ? nextValue : selectedMinute
            )
          ) {
            setCurrentValue(nextValue);
            scrollToOption(currentRef.current, nextValue);
          }
          break;
        case KEYS.TAB:
          if (!e.shiftKey && focusedOption === 'hour') {
            e.preventDefault();
            setFocusedOption('minute');
          } else if (e.shiftKey && focusedOption === 'minute') {
            e.preventDefault();
            setFocusedOption('hour');
          }
          break;
        case KEYS.ENTER:
          handleOk();
          break;
        case KEYS.ESC:
          setIsOpen(false);
          break;
      }
    },
    [isOpen, focusedOption, selectedHour, selectedMinute, validateTime, handleOk]
  );

  // Add scroll helper
  const scrollToOption = (container: HTMLDivElement | null, value: number) => {
    if (!container) return;
    const optionHeight = 32; // Height of each option
    container.scrollTop = value * optionHeight;
  };

  // Format time without timezone
  const formatTime = useCallback(
    (time: Dayjs) => {
      return time.format(format);
    },
    [format]
  );

  // Add effect for keyboard listeners
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  const handleNow = () => {
    setSelectedHour(value.hour());
    setSelectedMinute(value.minute());
    onSelect(value);
    setIsOpen(false);
  };
  useEffect(() => {
    if (isOpen && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const dropdownHeight = 250; // approx height of dropdown including padding
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setDropUp(true);
      } else {
        setDropUp(false);
      }
    }
  }, [isOpen]);

  // Update the click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Check if the click is on any of the dropdown elements
      const isDropdownElement = target.closest('.time-picker-dropdown');
      const isTimeOption = target.closest('.time-option');
      const isButton = target.closest('button');

      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(target) &&
        !isDropdownElement &&
        !isTimeOption &&
        !isButton
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <MainDiv dropUp={dropUp}>
      <TimePickerWrapper ref={wrapperRef}>
        <TimeInput
          readOnly
          disabled={disabled}
          value={
            selectedHour || selectedMinute
              ? `${selectedHour.toString().padStart(2, '0')}:${selectedMinute.toString().padStart(2, '0')}`
              : formatTime(value)
          }
          onClick={() => !disabled && setIsOpen(true)}
          style={style}
          aria-label={ariaLabel}
          aria-expanded={isOpen}
          role='combobox'
        />
      </TimePickerWrapper>
      <DropdownWrapper
        className='time-picker-dropdown'
        isOpen={isOpen}
        dropUp={dropUp}
        role='dialog'
        aria-label='Time picker dropdown'>
        <TimeColumns>
          <Column ref={hourColumnRef} role='listbox' aria-label='Hours' tabIndex={focusedOption === 'hour' ? 0 : -1}>
            {hours.map((hour) => (
              <TimeOption
                className='time-option'
                key={hour}
                selected={hour === selectedHour}
                onClick={() => setSelectedHour(hour)}
                role='option'
                aria-selected={hour === selectedHour}>
                {hour.toString().padStart(2, '0')}
              </TimeOption>
            ))}
          </Column>
          <Column
            ref={minuteColumnRef}
            role='listbox'
            aria-label='Minutes'
            tabIndex={focusedOption === 'minute' ? 0 : -1}>
            {minutes.map((minute) => (
              <TimeOption
                className='time-option'
                key={minute}
                selected={minute === selectedMinute}
                onClick={() => setSelectedMinute(minute)}
                role='option'
                aria-selected={minute === selectedMinute}>
                {minute.toString().padStart(2, '0')}
              </TimeOption>
            ))}
          </Column>
        </TimeColumns>
        <ButtonRow>
          <Button type='button' onClick={handleNow} aria-label='Set to current time'>
            Now
          </Button>
          <Button type='button' onClick={handleOk} aria-label='Confirm selection'>
            OK
          </Button>
        </ButtonRow>
      </DropdownWrapper>
    </MainDiv>
  );
};

export default CustomTimePicker;
