import styled from 'styled-components';
import { Typography } from '../../global/theme';

const MainDiv = styled.div<{ dropUp?: boolean }>`
  position: ${(props) => (props.dropUp ? 'relative' : '')};
`;

const TimePickerWrapper = styled.div`
  width: fit-content;
  position: relative;
`;

const TimeInput = styled.input<{ disabled?: boolean }>`
  padding: 6px 11px;
  ${Typography.body_lg}
  box-shadow: none;
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  background: var(--background-primary);
  color: var(--text-primary);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  &:focus-visible {
    outline: none;
  }
`;

const DropdownWrapper = styled.div<{ isOpen: boolean; dropUp?: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 6px;
  box-shadow: var(--shadow-dropdown);
  z-index: 1000;
  margin-top: ${(props) => (props.dropUp ? 'unset' : '4px')};
  bottom: ${(props) => (props.dropUp ? '100%' : 'unset')};
  margin-bottom: ${(props) => (props.dropUp ? '4px' : 'unset')};
`;

const TimeColumns = styled.div`
  display: flex;
  height: 200px;
`;

const Column = styled.div`
  width: 60px;
  overflow-y: auto;
  padding-top: 8px;
  border-right: 0.5px solid var(--border-primary);
  &:last-child {
    border-right: none;
  }

  /* Hide scrollbar by default */
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
    transition: width 0.3s ease;
  }

  /* Show scrollbar when scrolling */
  &:hover::-webkit-scrollbar,
  &:focus::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--text-placeholder);
    border-radius: 3px;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:focus::-webkit-scrollbar-thumb {
    visibility: visible;
  }
`;

const TimeOption = styled.div<{ selected?: boolean }>`
  padding: 5px;
  text-align: center;
  margin: 0 6px;
  cursor: pointer;
  border-radius: 4px;
  background: ${(props) => (props.selected ? 'var(--neutral-secondary)' : 'transparent')};
  :hover {
    background: var(--neutral-secondary);
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 10px 7px;
  border-top: 1px solid var(--border-primary);
`;

const Button = styled.button`
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background: ${(props) => (props.children === 'OK' ? 'var(--brand-primary)' : 'transparent')};
  color: ${(props) => (props.children === 'OK' ? 'white' : 'var(--text-primary)')};

  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export { MainDiv, TimePickerWrapper, TimeInput, DropdownWrapper, TimeColumns, Column, TimeOption, ButtonRow, Button };
