import axios from 'axios';
import { Dispatch, AnyAction } from 'redux';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  setCurrentWorkspace,
  setWorkspaceList,
  setWorkspaceLoader,
  setWorkspaceUsage
} from '../actions/workspaceActions';
import { getAPIErrorReason, getCookieValue, isDev } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { WORKSPACE_TEAM_TYPE } from '../global/constants';
import { WorkspaceInputDetailsInterface, WorkspaceInterfase } from '../interfaces/WorkspaceInterface';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { setAnlayticUserAndCompany, trackAnalyticActivity, workspaceSetAnalytics } from './analyticsService';
import { captureException } from './logService';
import { setWorksapceIdInAxios } from '../helpers/authTokenHelpers';
import { updateUserPreference } from '../helpers/firebaseHelper';
import { WORKSPACE_ANALYTICS } from '../global/analyticsConstants';
import { rootStore } from '../mobx/rootStore';

/**
 * @desc Workspace - Get workspace list
 * @param {*}
 */
export const getWorkspaceList: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setWorkspaceLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const params = {
      user_id: userDetails?.id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/getWorkspaceList`, { params });
    const { data } = response;
    if (data.length > 0) {
      const workspace = UserPreferenceSingleton.getInstance().getWorkspace();
      let selectedWorkspace = { id: '' };
      if (workspace) {
        selectedWorkspace = data.find((x: any) => x.id === workspace.id);
      } else {
        if (!isDev()) await updateUserPreference({ current_workspace_id: data[0]?.id });
        selectedWorkspace = data[0];
      }
      setWorksapceIdInAxios(selectedWorkspace?.id);
      workspaceSetAnalytics(selectedWorkspace?.id);
      UserPreferenceSingleton.getInstance().setWorkspace(selectedWorkspace);
      dispatch(setCurrentWorkspace(selectedWorkspace));
      rootStore.workspaceStore.setCurrentWorkspace(selectedWorkspace as any); //Remove any
      dispatch(setWorkspaceList(data));
    } else if (data && data?.length === 0) {
      UserPreferenceSingleton.getInstance().setWorkspace(null);
      dispatch(setCurrentWorkspace(null));
      rootStore.workspaceStore.setCurrentWorkspace(null);
      dispatch(setWorkspaceList([]));
    }
    return true;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get workspace list please try again';
    captureException(error);
    dispatchWorkspaceError(error, dispatch);
    return false;
  } finally {
    dispatch(setWorkspaceLoader(false));
  }
};

/**
 * @desc Workspace - Get workspace details
 */
export const getWorkspaceDetails: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!workspace_id) return false;
    dispatch(setWorkspaceLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const params = {
      user_id: userDetails?.id,
      workspace_id: workspace_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/getWorkspaceList`, { params });
    const { data } = response;
    if (data) {
      setWorksapceIdInAxios(data?.id);
      workspaceSetAnalytics(data?.id);
      UserPreferenceSingleton.getInstance().setWorkspace(data);
      dispatch(setCurrentWorkspace(data));
      rootStore.workspaceStore.setCurrentWorkspace(data as any); //Remove any
      return data;
    } else {
      UserPreferenceSingleton.getInstance().setWorkspace(null);
      dispatch(setCurrentWorkspace(null));
      rootStore.workspaceStore.setCurrentWorkspace(null);
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get workspace details please try again';
    captureException(error);
    dispatchWorkspaceError(error, dispatch);
    return false;
  } finally {
    dispatch(setWorkspaceLoader(false));
  }
};

/**
 * @desc Workspace - Create new workspace
 */
export const createWorkspace: any =
  (payloadData: WorkspaceInputDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setWorkspaceLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

      // Extract cookie values
      const utmSource = getCookieValue('utm_source');
      const utmMedium = getCookieValue('utm_medium');
      const utmCampaign = getCookieValue('utm_campaign');
      const utmTerm = getCookieValue('utm_term');
      const utmContent = getCookieValue('utm_content');

      const payload = {
        ...payloadData,
        userId: userDetails?.id,
        isDev: isDev()
      };
      if (utmSource || utmMedium || utmCampaign || utmTerm || utmContent) {
        (payload as any).utmData = {
          utmSource,
          utmMedium,
          utmCampaign,
          utmTerm,
          utmContent
        };
      }
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/company/postWorkspace`, payload);
      const { data } = response.data;
      if (data) {
        const eventProperties = {
          workspace_name: data?.name,
          workspace_type: WORKSPACE_TEAM_TYPE[data?.teamType],
          industry: data?.industry
        };
        trackAnalyticActivity(WORKSPACE_ANALYTICS.CREATED, eventProperties);
        const intercomPayload = {
          company: {
            name: data?.name,
            id: data?.id
          }
        };
        if (window && typeof (window as any)?.Intercom === 'function') {
          (window as any).Intercom('update', intercomPayload);
        }
        if ((window as any).posthog) {
          (window as any).posthog.group('Workspace', intercomPayload.company?.id, {
            name: intercomPayload.company?.name
          });
        }
        setWorksapceIdInAxios(data?.id);
        workspaceSetAnalytics(data?.id);
        UserPreferenceSingleton.getInstance().setWorkspace(data);
        dispatch(setCurrentWorkspace(data));
        rootStore.workspaceStore.setCurrentWorkspace(data as any); //Remove any
        setAnlayticUserAndCompany(userDetails, data);
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to Create Workspace please try again';
      captureException(error, 'onboarding');
      dispatchWorkspaceError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setWorkspaceLoader(false));
    }
  };

/**
 * @desc Workspace - Check step one form validation
 * @param {*}
 */
export const checkStepOneValidation: any =
  (payload: WorkspaceInputDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!payload || !payload.name || payload?.name.trim() === '') {
        dispatchWorkspaceError('Name is required', dispatch);
        return false;
      } else if (!payload.teamType) {
        dispatchWorkspaceError('Team type is required', dispatch);
        return false;
      } else if (!payload.industry || payload?.industry?.trim() === '') {
        dispatchWorkspaceError('Industry is required', dispatch);
        return false;
      }
      return true;
    } catch (e) {
      captureException(e);
      return false;
    }
  };

/**
 * @desc Workspace - Update workspace details
 */
export const onUpdateWorkspaceDetails: any =
  (workspace_id: string, payloadData: WorkspaceInterfase) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!workspace_id) return false;
      dispatch(setWorkspaceLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        ...payloadData,
        companyId: workspace_id,
        userId: userDetails?.id
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/company/putWorkspaceDetails`, payload);
      if (response) {
        if (response.data.message) {
          dispatch(setSuccessMessage(response.data.message));
          return true;
        } else if (response.data.error) {
          dispatch(setErrorMessage(response.data.error));
          return false;
        }
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update workspace details please try again';
      captureException(error);
      dispatchWorkspaceError(error, dispatch);
      return false;
    } finally {
      dispatch(setWorkspaceLoader(false));
    }
  };

/**
 * @desc Workspace - Get usage details
 */
export const getWorkspaceUsageDetails: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setWorkspaceLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/getUsageStorage`);
    if (response) {
      if (response.data) {
        dispatch(setWorkspaceUsage(response.data));
        return response.data;
      }
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get workspace usage details please try again';
    captureException(error);
    dispatchWorkspaceError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setWorkspaceLoader(false));
  }
};

/**
 * @desc Workspace - Send Delete Request workspace
 */
export const sendDeleteRequest: any = (isResend: boolean) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setWorkspaceLoader(true));
    const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/company/deleteRequest`);
    if (response) {
      dispatch(setSuccessMessage(isResend ? 'Verification code resend successfully' : 'Delete request send'));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to send delete request for workspace. Please try again.';
    captureException(error);
    dispatchWorkspaceError(error, dispatch);
    return false;
  } finally {
    dispatch(setWorkspaceLoader(false));
  }
};

/**
 * @desc Workspace - Delete workspace
 */
export const deleteWorkspace: any =
  (companyId: string, verificationCode: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!companyId || !verificationCode) return false;
      dispatch(setWorkspaceLoader(true));
      const response = await axios.delete(
        `${REACT_APP_TEAMCAMP_APIURL}/company/${companyId}/delete/${verificationCode}`
      );
      if (response) {
        dispatch(setSuccessMessage('Workspace deleted successfully'));
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to delete workspace, please try again.';
      captureException(error);
      dispatchWorkspaceError(error, dispatch);
      return false;
    } finally {
      dispatch(setWorkspaceLoader(false));
    }
  };

export function dispatchWorkspaceError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}

export function dispatchWorkspaceSuccess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}
