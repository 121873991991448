import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--background-primary);
  margin: 0 -16px;
  padding: 0 20px 8px;
  gap: 4px;
`;
const Heading = styled.p`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;
const SearchBlock = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 0;
  p {
    ${Typography.heading_lg}
  }
`;
const SearchArea = styled.div`
  position: relative;
  input {
    padding: 9px 16px;
    width: -webkit-fill-available;
    outline: 0;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
    color: var(--text-primary);
    border-radius: 8px;
    ${Typography.body_md}
    ::placeholder {
      color: var(--text-placeholder);
    }
    :focus {
      border: 0.5px solid var(--brand-primary);
    }
  }
`;
const SearchIcon = styled.div`
  position: absolute;
  top: 16px;
  left: 10px;
  height: 16px;
  svg {
    fill: var(--text-primary);
  }
  .search-icon {
    fill: var(--text-secondary);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 9px;
  right: 10px;
  height: 24px;
  cursor: pointer;
  .close-icon {
    stroke: var(--text-secondary);
    width: 24px;
    height: 24px;
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
export { Header, Heading, SearchBlock, SearchArea, SearchIcon, CloseIcon };
