import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  textBlack?: boolean;
  isApplyMargin?: boolean;
  isTaskDetails?: boolean;
  isUpdateReducer?: boolean;
  isCalendarhover?: boolean;
  taskGrid?: boolean;
  isCustomcontextmenu?: boolean;
  isSelected?: boolean;
  isSelectedRepeat?: boolean;
  isSelectedTime?: boolean;
  isShareDocModel?: boolean;
  isShowSubmenu?: boolean;
  isOptionSelected?: boolean;
  isShowMonthlySubmenu?: boolean;
  isShowWeeklySubmenu?: boolean;
  isSubmenuMonthly?: boolean;
  isHideText?: boolean;
  changeIconColor?: boolean;
}
const AssignBoxs = styled.div<Props>`
  min-width: 200px;
  ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      .menuStyle {
        padding: 0px;
        box-shadow: none;
        .menuitem {
          :hover {
            background: none;
          }
        }
        :hover {
          background: none;
        }
      }

      .ant-menu-vertical .ant-menu-item {
        margin: 0;
        line-height: unset;
        height: unset;
        width: 100%;
        border-radius: 0;
      }
      .ant-menu-light.ant-menu-root.ant-menu-vertical {
        border-inline-end: none;
      }
    `}
`;

const AssignHead = styled.form`
  padding: 4px 16px 4px 12px;
  @media (max-width: 449px) {
    padding: 0px 12px 0px 22px;
  }
`;

const AssignBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const Calender = styled.div<Props>`
  .icon {
    width: 14px;
    height: 14px;
    @media (max-width: 449px) {
      width: 16px;
      height: 16px;
    }
  }
  .text-center {
    margin: 0;
    ${Typography.body_md}
    color: var(--text-primary);
    ${(props: Props) =>
      props.textBlack &&
      css`
        color: var(--text-primary);
      `}
  }
  @media (max-width: 449px) {
    padding: 8px 0px;
  }

  .react-calendar {
    max-width: 100%;
    ${Typography.body_xs}
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--text-secondary);
    @media (max-width: 449px) {
      padding: 0 4px;
    }
  }
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar__month-view {
    flex-grow: unset;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    border-radius: 36px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    margin-bottom: 13px;
    padding: 4px 0;
    @media (max-width: 449px) {
      margin-bottom: 16px;
      padding: 4px 0;
      gap: 12px;
    }
  }
  .react-calendar__navigation button {
    ${Typography.body_lg_semibold}
    font-family: 'Inter';
    background: none;
    height: 20px;
    padding: 0;
    color: var(--text-primary);
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 50px;
    }
    @media (max-width: 449px) {
      font-size: 16px;
      line-height: 16px;
      height: 16px;
      padding: 0;
      width: 26px;
      height: 26px;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 8px;
    @media (max-width: 449px) {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 12px;
    }
  }
  .react-calendar__month-view__weekdays__weekday {
    @media (max-width: 449px) {
      padding: 0;
    }
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    ${Typography.body_xs}
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2px 2px;
  }
  .react-calendar__year-view__months__month {
    overflow: auto;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 2px;
    background: none;
    text-align: center;
    ${Typography.body_md}
    color: var(--text-primary);
    @media (max-width: 449px) {
      padding: 12px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: var(--neutral-secondary);
    border-radius: 36px;
  }
  .react-calendar__tile--now {
    border-radius: 36px;
  }
  .react-calendar__tile--active:enabled:hover {
    background: var(--text-secondary);
    color: var(--background-primary);
  }

  .react-calendar__tile--active:enabled {
    background: var(--text-secondary);
    color: var(--background-primary);
    @media (max-width: 449px) {
      flex: unset !important;
      max-width: 44px;
      width: 100%;
    }
  }

  .react-calendar__tile--active {
    border-radius: 36px;
    background: var(--text-primary);
    color: var(--text-white);
  }

  .react-calendar__year-view__months {
    row-gap: 3px;
  }
`;
const Icon = styled.div<Props>`
  cursor: pointer;

  ${(props: Props) =>
    props.isCalendarhover &&
    css`
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-radius: 4px;
        background-color: var(--neutral-secondary);
      }
    `}
  .fill-color {
    width: 16px;
    height: 16px;
    fill: var(--text-secondary);
  }

  &:hover .fill-color {
    fill: var(--text-primary);
  }
  &:active .fill-color {
    fill: var(--text-primary);
  }
  .calendar-fill-color {
    fill: var(--text-secondary);
  }
  .calendar-due-fill-color {
    fill: var(--accent-error);
  }
  display: flex;
  p {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    white-space: nowrap;
    margin: 0;
    h6 {
      ${Typography.body_md}
      color: var(--text-secondary);
      white-space: nowrap;
      margin: 0;
      :hover {
        color: var(--text-primary);
      }
    }
  }
  .sharedoc {
    margin-left: 8px;
    ${Typography.body_sm_medium}
    padding-top: 1px;
  }

  ${(props: Props) =>
    props.isTaskDetails &&
    css`
      display: flex;
      align-items: center;
      padding: 5px 11px;
      gap: 10px;
      border-radius: 6px;
      border: 0.5px solid transparent;
      cursor: pointer;
      pointer-events: auto;

      ul {
        list-style-type: none;
      }

      li {
      }
      .plus-icon {
        visibility: hidden;
      }
      :hover {
        background: var(--background-primary);
        border: 0.5px solid var(--border-primary);
        border-radius: 6px;
      }
      &:hover .fill-color {
        fill: var(--text-secondary);
      }
    `}
  ${(props: Props) =>
    props.taskGrid &&
    css`
      min-width: 24px;
      width: 100%;
    `}
  .task-grid-calander-icon {
    display: none;
    padding: 3px;
    border: 0.5px solid var(--border-primary);
    border-radius: 4px;
    width: 16px;
    height: 16px;
    path {
      fill: var(--text-secondary);
    }
  }
  .task-grid-calander-icon:hover {
    background-color: var(--neutral-secondary);
    border: 0.5px solid transparent;
  }
  ${(props: Props) =>
    props.isShareDocModel &&
    css`
      padding: 6px 16px;
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      border-radius: 36px;
    `}
`;

const Createtask = styled.div<Props>`
  padding: 4px 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  border: 0.5px solid var(--border-primary);
  .secondicon {
    fill: var(--text-secondary);
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 5px;
    h6 {
      color: var(--text-primary) !important;
    }
    .secondicon {
      fill: var(--text-primary);
    }
  }
  .responsive {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    white-space: nowrap;
    margin: 0;
  }
  h6 {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
    white-space: nowrap;
    margin: 0;
  }
  ${(props: Props) =>
    props.isHideText &&
    css`
      &.show-text {
        padding: 2px 7px;
        height: unset;
      }
      h6 {
        display: none;
      }
      @media (max-width: 449px) {
        padding: 4px 7px !important;
        &.show-text {
          padding: 2px 7px !important;
          height: unset !important;
        }
        h6 {
          display: none !important;
        }
      }
    `}
  @media (max-width: 449px) {
    .responsive {
      display: inline-flex;
    }
    h6 {
      display: inline-flex;
    }
    padding: 5px;
    display: flex;
    height: 16px;
    p {
      display: inline-flex;
    }
    svg {
      path {
        ${(props: Props) =>
          props.changeIconColor &&
          css`
            fill: var(--text-secondary) !important ;
          `}
      }
    }
  }
`;
const Bottomsection = styled.div<Props>`
  padding: 5px 10px;
  display: flex;
  position: relative;
  justify-content: center;
  ${(props: Props) =>
    props.isSelectedRepeat &&
    css`
      flex-direction: column-reverse;
      padding: 0;
    `}
  ${(props: Props) =>
    props.isSelectedTime &&
    css`
      flex-direction: column;
      padding: 0;
    `}
`;

const Rightside = styled.div<Props>`
  display: flex;
  gap: 8px;
  min-width: 218px;

  cursor: pointer;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_md}
  }
  :hover {
    p {
      color: var(--text-secondary);
    }
  }
  .stroke-color {
    stroke: var(--text-secondary);
  }
  ${(props: Props) =>
    props.isSelectedRepeat &&
    css`
      gap: 12px;
      padding: 0;
      justify-content: flex-start;
      .stroke-color {
        width: 24px;
        height: 24px;
      }
    `}
`;

const View = styled.p<Props>`
  width: 114px;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0px;
  cursor: pointer;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .svgicon {
    display: none;
  }

  &:hover .svgicon {
    display: block;
    stroke: var(--text-primary);
  }
`;

const Div = styled.div<Props>`
  display: flex;
  position: relative;
  justify-content: center;
  ${(props: Props) =>
    props.isSelectedRepeat &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 12px;
    `}
  ${(props: Props) =>
    props.isSelectedTime &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 12px 7px 12px;
      border-top: 0.5px solid var(--border-primary);
    `}
`;

const Weekly = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  cursor: pointer;
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_md}
  }
  &:hover {
    p {
      color: var(--text-secondary);
    }
  }
`;
const CloseIcon = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  .close-icon {
    stroke: var(--text-secondary);
  }
`;

const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  ${Typography.body_md}
`;

const CalenderMainDiv = styled.div`
  align-items: center;
  padding: 8px;
  max-height: unset !important;
  @media (max-width: 449px) {
    padding: 0 10px;
  }
`;
export {
  AssignBoxs,
  AssignHead,
  AssignBlock,
  Calender,
  Icon,
  Createtask,
  Bottomsection,
  Rightside,
  View,
  Div,
  Weekly,
  CloseIcon,
  Submenu,
  CalenderMainDiv
};
