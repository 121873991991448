import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isSearchScreen?: boolean;
}

const PopUp = styled.div`
  position: absolute;
  top: calc(50% - 218px);
  left: calc(50% - 327px);
  .vercel [cmdk-root] {
    width: 598px;
    background: var(--background-primary);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 16px 70px rgba(0, 0, 0, 0.2);
    transition: transform 100ms ease;
  }

  .dark .vercel [cmdk-root] {
    background: rgba(22, 22, 22, 0.7);
  }

  .vercel [cmdk-input] {
    border: none;
    width: 536px;
    ${Typography.body_lg}
    outline: none;
    color: var(--text-primary);
    border-radius: 0;
    background-color: var(--background-primary);
    margin-bottom: 0;
    padding: 0;
  }

  .vercel [cmdk-input]::placeholder {
    color: var(--text-placeholder);
  }

  .vercel [cmdk-vercel-badge] {
    height: 20px;
    background: var(--neutral-secondary);
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    ${Typography.body_sm_medium}
    color: var(--text-secondary);
    border-radius: 4px;
    margin: 4px 0 4px 12px;
    user-select: none;
    text-transform: capitalize;
  }

  .vercel [cmdk-item] {
    content-visibility: auto;
    cursor: pointer;
    border-radius: 8px;
    ${Typography.body_md}
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 6px 8px;
    height: 24px;
    color: var(--text-secondary);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;
    span {
      color: var(--text-secondary);
    }
    @media (max-width: 449px) {
      ${Typography.body_md}
      padding: 11px 12px 11px 8px;
      color: var(--text-primary);
      height: unset;
      gap: 8px;
    }
  }

  .vercel [cmdk-item] .search-profile {
    span {
      color: var(--text-white);
    }
  }

  .vercel [cmdk-item][data-selected='true'] {
    background: var(--neutral-secondary);
    color: var(--text-primary);
    border-radius: 8px;
    kbd {
      color: var(--text-primary);
    }
    @media (max-width: 449px) {
      background: unset;
      color: var(--text-primary);
    }
  }

  .vercel [cmdk-item][data-disabled='true'] {
    color: #c7c7c7;
    cursor: not-allowed;
  }

  .vercel [cmdk-item]:active {
    transition-property: background;
    background: var(--neutral-secondary);
    color: var(--text-primary);
  }

  .vercel [cmdk-item] svg {
    width: 18px;
    height: 18px;
    @media (max-width: 449px) {
      width: 20px;
      height: 20px;
    }
  }

  .vercel [cmdk-list] {
    height: min(330px, var(--cmdk-list-height));
    max-height: 400px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
    padding: 0 12px 16px 12px;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .vercel [cmdk-vercel-shortcuts] {
    display: flex;
    margin-left: auto;
    gap: 4px;
    @media (max-width: 449px) {
      display: none;
    }
  }

  .vercel [cmdk-vercel-shortcuts] kbd {
    ${Typography.body_sm}
    min-width: 14px;
    padding: 4px;
    height: 14px;
    border-radius: 4px;
    color: var(--text-secondary);
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .vercel [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: #e8e8e8;
    margin: 4px 0;
  }

  .vercel *:not([hidden]) + [cmdk-group] {
    margin-top: 8px;
    .setting-label {
      margin-top: 12px;
    }
    :nth-child(2) {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 48px;
    }
    @media (max-width: 449px) {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      &.projects-label {
        margin-top: 0;
      }
    }
  }
  .vercel *:not([hidden]) + [cmdk-group].setting-label {
    margin-top: 12px;
  }

  .vercel [cmdk-group-items] {
    @media (max-width: 449px) {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
  .vercel [cmdk-group-heading] {
    user-select: none;
    ${Typography.body_sm_medium}
    color: var(--text-secondary);
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media (max-width: 449px) {
      ${Typography.body_lg_semibold}
      margin-bottom: 0px;
    }
  }

  .vercel [cmdk-empty] {
    ${Typography.body_lg}
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    width: calc(100% - 32px);
    left: 50%;
    transform: translateX(-50%);
    .vercel [cmdk-root] {
      width: 100%;
    }
  }
  ${(props: Props) =>
    props.isSearchScreen &&
    css`
      position: relative;
      top: unset;
      left: unset !important;
      transform: unset !important;
      width: 100vw !important;
      margin-left: -16px;
      .vercel [cmdk-list] {
        height: calc(100dvh - 168px);
        max-height: calc(100dvh - 168px);
        overflow: auto;
        ::-webkit-scrollbar {
          display: contents;
        }
        padding: 0 0 16px 16px;
        @media (max-width: 449px) {
          padding: 16px 0 16px 16px;
          max-height: calc(100dvh - 174px);
        }
      }
      .vercel [cmdk-root] {
        width: 100%;
        background: var(--background-primary);
        border-radius: 0;
        box-shadow: unset;
      }
    `}
`;

const Main = styled.div`
  z-index: 99999;
`;
const Icon = styled.div`
  display: inline-flex;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Header = styled.div<Props>`
  display: flex;
  max-width: 598px;
  padding: 16px 20px 15px 20px;
  border-bottom: 0.5px solid var(--border-primary);
  ${(props: Props) =>
    props.isSearchScreen &&
    css`
      display: none;
    `}
`;
const Tab = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 20px;
  @media (max-width: 449px) {
    display: none;
    button {
      padding: 7px 14px;
      ${Typography.body_md_medium}
    }
  }
`;
const Inputvalue2 = styled.input``;
const Checkbox = styled.div`
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;

const Emptystate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 41px 0;
  p {
    color: var(--text-placeholder);
  }
  @media (max-width: 449px) {
    padding: 0;
    height: calc(100dvh - 200px);
    img {
      display: none;
    }
  }
`;
export { PopUp, Main, Icon, Header, Tab, Inputvalue2, Checkbox, Emptystate };
