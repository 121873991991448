import React from 'react';
import { Group, Container, Emptyblock, Emptycontent, Text, Title, Menu } from './styles';
import Button from '../button';
import { isEmpty } from '../../helpers/common';

interface EmptyValue {
  name?: string;
  header?: string;
  title?: string;
  onButtonClick?: () => void;
  image?: string;
  hideButton?: boolean;
  ismargin?: number;
  isgap?: number;
  ismaxwidth?: boolean;
  isMessageEmpty?: boolean;
  isProjectEmpty?: boolean;
  isInboxEmpty?: boolean;
  isReportTimeEmpty?: boolean;
  isApiKeyEmpty?: boolean;
  linktext?: string;
  href?: string;
  isSearchmodalEmpty?: boolean;
  isMilestoneTaskEmpty?: boolean;
  hasNotPlusIcon?: boolean;
  isOverviewEmpty?: boolean;
  isCustomerDetail?: boolean;
  isCustomerMemberEmpty?: boolean;
  isAllProjectEmpty?: boolean;
}

const EmptyState: React.FC<EmptyValue> = (props) => {
  const {
    hideButton = false,
    ismargin,
    isgap,
    ismaxwidth,
    isMessageEmpty,
    isProjectEmpty,
    isInboxEmpty,
    isReportTimeEmpty,
    isApiKeyEmpty = false,
    isSearchmodalEmpty = false,
    isMilestoneTaskEmpty = false,
    isOverviewEmpty = false,
    isCustomerDetail = false,
    isCustomerMemberEmpty = false,
    isAllProjectEmpty
  } = props;
  return (
    <>
      <Group isMessageEmpty={isMessageEmpty} isProjectEmpty={isProjectEmpty} isAllProjectEmpty={isAllProjectEmpty}>
        <Container
          ismargin={ismargin}
          ismaxwidth={ismaxwidth}
          isMessageEmpty={isMessageEmpty}
          isProjectEmpty={isProjectEmpty}
          isInboxEmpty={isInboxEmpty}
          isReportTimeEmpty={isReportTimeEmpty}>
          <Emptyblock
            isgap={isgap}
            isInboxEmpty={isInboxEmpty}
            isMessageEmpty={isMessageEmpty}
            isSearchmodalEmpty={isSearchmodalEmpty}
            isMilestoneTaskEmpty={isMilestoneTaskEmpty}
            isOverviewEmpty={isOverviewEmpty}
            isCustomerMemberEmpty={isCustomerMemberEmpty}
            isAllProjectEmpty={isAllProjectEmpty}
            isCustomerDetail={isCustomerDetail}>
            {!isEmpty(props.image) && <img src={props.image} alt='' width={200} height={110} />}

            <Emptycontent isApiKeyEmpty={isApiKeyEmpty} isOverviewEmpty={isOverviewEmpty}>
              {!isAllProjectEmpty && (
                <Text
                  isMilestoneTaskEmpty={isMilestoneTaskEmpty}
                  isOverviewEmpty={isOverviewEmpty}
                  isCustomerDetail={isCustomerDetail}
                  isCustomerMemberEmpty={isCustomerMemberEmpty}>
                  {props.header}
                </Text>
              )}
              <Title
                isApiKeyEmpty={isApiKeyEmpty}
                isSearchmodalEmpty={isSearchmodalEmpty}
                isAllProjectEmpty={isAllProjectEmpty}>
                {props.title}
                {isApiKeyEmpty && (
                  <a href={props.href} target='_blank' rel='noreferrer'>
                    {props.linktext}
                  </a>
                )}
              </Title>
            </Emptycontent>
            {!hideButton && (
              <Menu
                onClick={props.onButtonClick}
                style={{ padding: 0 }}
                isMilestoneTaskEmpty={isMilestoneTaskEmpty}
                isOverviewEmpty={isOverviewEmpty}
                isCustomerDetail={isCustomerDetail}>
                <Button
                  title={props.name}
                  iconName={props.hasNotPlusIcon ? '' : 'plus-icon'}
                  type='button'
                  iconSize={18}
                  iconViewBox='0 0 18 18'
                  iconColor='#fff'
                  modelbtn={true}
                  className='button'
                />
              </Menu>
            )}
          </Emptyblock>
        </Container>
      </Group>
    </>
  );
};

export default EmptyState;
