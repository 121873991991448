/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isMinWidth?: number;
  trigger?: 'click' | 'hover' | 'contextmenu';
  isRemovePadding?: boolean;
  isRemoveMaxHeight?: boolean;
}

const DropdownWrapper = styled.div<Props>`
  position: relative;
  display: flex;
  pointer-events: auto;
`;

const TriggerWrapper = styled.div<Props>`
  cursor: pointer;
  position: relative;
  ${(props) =>
    props.trigger === 'contextmenu' &&
    css`
      width: 100%;
    `}
`;

const DropdownMenu = styled.div<Props>`
  position: absolute;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  background-color: var(--background-primary);
  color: var(--text-primary);
  box-shadow: var(--shadow-dropdown);
  z-index: 10000;
  padding: 4px 0;
  max-width: 198px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-top: 4px;
  ${(props: Props) =>
    props.isMinWidth &&
    css`
      min-width: ${props.isMinWidth}px;
    `}
  ${(props: Props) =>
    props.isRemovePadding &&
    css`
      padding: 0;
    `}
`;

const Divider = styled.div`
  height: 0.5px;
  background-color: var(--border-primary);
  margin: 3px 0;
`;

const StatusName = styled.div`
  text-overflow: ellipsis;
  width: 99px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--text-primary);
  margin: 0px;
  ${Typography.body_md}
`;

const DropHeader = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 6px 14px 6px 10px;
`;

const InputArea = styled.input`
  max-width: 100%;
  width: 116px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_md}
  color: var(--text-primary);
  background-color: var(--background-primary);
  margin: 0;
  padding: 0;
  border: none;

  :focus {
    outline: none;
  }
  .placeholder {
    color: var(--text-secondary);
  }
  @media (max-width: 449px) {
    max-width: 297px;
    width: 100%;
  }
`;

const ResponsiveModalMainDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-overlay);
  display: flex;
  padding: 0 16px;
  z-index: 9999;
`;

const ResponsiveDropdownMenu = styled.div`
  margin: auto;
  background: var(--background-primary);
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 16px;
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  z-index: 9999;
  position: relative;
`;

const ModalTitle = styled.p`
  margin: 0;
  ${Typography.body_lg_semibold}
  color: var(--text-secondary);
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px 6px 22px;
`;

const ModalCrossIcon = styled.div`
  background: var(--neutral-secondary);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 13px;
`;

const ResponsiveSearchInput = styled.div`
  position: relative;
`;

const Icon = styled.div`
  position: absolute;
  z-index: 11;
  top: 12px;
  left: 22px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemList = styled.div<Props>`
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  max-height: 185px;
  @media (max-width: 449px) {
    max-height: 286px;
  }
  ${(props) =>
    props.isRemoveMaxHeight &&
    css`
      max-height: unset;
      @media (max-width: 449px) {
        max-height: unset;
      }
    `}
`;

export {
  DropdownMenu,
  DropdownWrapper,
  TriggerWrapper,
  Divider,
  StatusName,
  DropHeader,
  InputArea,
  ModalCrossIcon,
  ModalHeader,
  ModalTitle,
  ResponsiveDropdownMenu,
  ResponsiveModalMainDiv,
  Icon,
  ResponsiveSearchInput,
  ItemList
};
